*{margin:0; padding:0; border:0; outline:0; text-decoration:none; box-sizing:border-box; -moz-box-sizing:border-box; -webkit-box-sizing:border-box; font-family:'Poppins', sans-serif;}
body,html{float:left; width:100%; /* overflow-x:hidden; */}
body{font-family:'Poppins', sans-serif; font-weight:400; font-size:15px; color:#000; overflow-x:hidden;}
.main-wrapper{float:left; width:100%;}
.container{float:left; width:100%; max-width:1290px; padding:0 15px; position: relative;  left: 50%; transform: translateX(-50%);}
.logo{float: left;}
.MuiGrid-spacing-xs-5{ width: calc(100% + 30px) !important; margin: -15px !important;}
.MuiGrid-spacing-xs-5 > .MuiGrid-item{padding: 0 15px !important;}
 
section,#root{float:left; width:100%;}
section{padding:45px 0;}
@media screen and (max-width:1200px){
  section{padding:30px 0;}
}
/* ========================================================================= */
.tl{text-align: left !important;}
a{text-decoration:none; color:inherit;}
img,svg{float:left; max-width:100%; height:auto;}
p{float:left; width:100%; margin-bottom:15px; line-height:1.5;}
ul,li,ol{list-style:none;}
input,select,textarea,button{font-family:'Poppins', sans-serif !important;}
a{font-weight:inherit;}
.MuiRadio-colorSecondary.Mui-checked{color:#14B9DB !important;}
.MuiRadio-colorSecondary.Mui-checked + span{font-weight: 500 !important;}
.btn-back{float:left; display:flex;}
.btn-back svg{float:left; margin-right:10px;}
.mrgn-top-30{margin-top:30px !important;}
.no-service-msg{float:left; width:100%; text-align:center; color:#8395A1; padding:25px 15px; font-size: 15px;}
.err {float:left; width:100%; margin:10px 0; color:red;}
.calIcon{float:left; position:relative; }
.calIcon + .MuiTextField-root{opacity:0; position:absolute;  pointer-events:none;}
.btn-link{float:left; margin-top:15px;text-decoration:underline; font-size:13px; font-weight:500;}
.float-right{float:right;}
.color-blue{color:#53C0D5;}
.MuiOutlinedInput-notchedOutline{border-color:#EAEAEA !important; border-radius:10px; -moz-border-radius:10px; -webkikt-border-radius:10px;}
.form-txt-ctrl{float:left; width:100%; margin-top:15px; font-size:13px;}
.form-txt-ctrl .btn-link{margin-top:0;}
.MuiOutlinedInput-input{padding:15.5px 28px !important;}
.MuiCheckbox-root {margin-left:2px !important;}
.MuiPickersDay-daySelected{background:#14BFDB !important;}
.MuiPickersCalendarHeader-iconButton{background-color:inherit !important;}
.MuiPickersCalendarHeader-iconButton svg{fill:#fff !important;}
.MuiPickersCalendarHeader-iconButton.Mui-disabled svg{fill:rgba(255, 255, 255, .5) !important;}
.MuiPickersCalendarHeader-switchHeader{margin:0 0 12px 0 !important; background:#14BFDB !important;}
.MuiPickersCalendarHeader-transitionContainer .MuiTypography-body1{font-family:'Poppins', sans-serif !important; font-weight:500 !important; color:#fff;}
.MuiGrid-spacing-xs-8 > .MuiGrid-item{padding: 0 10px !important;}
.MuiGrid-spacing-xs-8{margin: 0 -10px !important; width: calc(100% + 20px) !important; padding-top: 10px;}
.MuiOutlinedInput-root{border-radius:10px !important; -moz-border-radius:10px !important; -webkit-border-radius:10px !important;}
.MuiDialog-paperScrollPaper{overflow-y: hidden !important;}
.errTimeMsg{float: left; width: 100%; margin-top:50px;}
.errTimeMsg p{float: left; width: 100%; font-style: 16px; text-align: center; line-height: 1.5;}
.errTimeMsg a{color: #14B9DB; text-decoration: underline;}

.form-note{float: left; width: 100%; margin-top: 40px; font-size: 12px; line-height: 1.5; color: #082B44; margin-bottom: 0; text-align: justify;}
.MuiToolbar-gutters{padding: 0 !important;}
.btn-mob-stick{float: left; width: 100%; position: fixed; left: 0; bottom: 0; background: #fff;  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1); padding: 10px 15px; display: flex; justify-content: center; display: none;} 

/*========= SLICK SLIDER ARROW ========*/
button.slick-arrow{float:left; width:46px; height:46px; position:absolute; overflow:hidden; line-height:1000px; cursor:pointer; z-index:2; top:50%; transform:translateY(-50%); border-radius:100%; box-shadow:0 0 8px rgba(0,0,0,.107545); border:0;}
button.slick-arrow.slick-prev{background:url(/images/slide-L.svg) no-repeat #fff 50% 50%; left:20px;}
button.slick-arrow.slick-next{background:url(/images/slide-R.svg) no-repeat #fff 50% 50%; right:20px;}

/*========= SLICK SLIDER DOTS ========*/
.home-banner .slick-dots{bottom: 10px;}
.home-banner .slick-dots li{width: 7px;}
.home-banner .slick-dots li button:before{color:rgba(255, 255, 255, .9);}
.home-banner .slick-dots li.slick-active button:before{color:#fff;}

/*========= PLACEHOLDER CSS ========*/
::-webkit-input-placeholder{color:#f4f4f4;}
:-moz-placeholder          {color:#f4f4f4; opacity:1;}
::-moz-placeholder         {color:#f4f4f4; opacity:1;}
:-ms-input-placeholder     {color:#f4f4f4;}

/*========= HEADING CSS ========*/
h1, h2, h3, h4, h5, h6{float:left; width:100%;font-weight:normal; margin-bottom:20px; line-height:1.3; font-family:'Poppins', sans-serif !important;}  
h1{font-size:32px;}   
h2{font-size:28px;}   
h3{font-size:24px;}  
h4{font-size:22px;}  
h5{font-size:18px;}  
h6{font-size:16px;}  

/*========= STEPS BULLETE ========*/
.bult-steps{float:left; margin-top: -5px;}
.bult-steps li {float:left; margin-right:8px;}
.bult-steps li a{float:left; width:8px; height:8px; background:#fff; border-radius:100%; -moz-border-radius:100%; -webkit-border-radius:100%; opacity:0.30;} 
.bult-steps li a.active{opacity:1;}

/*========= HEADING CSS ========*/
.rating-wrapp{ float:left;  width:100%; display:flex; align-items:center;}
.rating-wrapp span{ float:left; white-space:nowrap; padding-top:4px; font-family:'Prata';}
.rating-wrapp .star-ratings{border:none; float:left; margin:0 15px; position:relative;} 
/* .rating-wrapp .star-ratings svg path{fill:#DFE7EC  !important;}  */

  /*========= HIGHTLIGHTS AND HOVER ========*/ 
.rating input:checked ~ label, /* show gold star when clicked */
.rating:not(:checked) > label:hover, /* hover current star */
.rating:not(:checked) > label:hover ~ label{color:#F46451;} /* hover previous stars in list */ 
.rating input:checked + label:hover, /* hover current star when changing rating */
.rating input:checked ~ label:hover,
.rating label:hover ~ input:checked ~ label, /* lighten current selection */
.rating input:checked ~ label:hover ~ label{color:#f39184;} 

/*========= BUTTON ========*/ 
button.MuiButton-root{border:1px solid #FCC761; height: 50px; font-size: 18px; background:none; color:#FCC761; box-shadow:none; padding:7px 30px;  border-radius:10px; -moz-border-radius:10px; -webkikt-border-radius:10px; text-transform: capitalize;}
button.MuiButton-root:hover{background:none; box-shadow:none;}
button.btn-blue,button.btn-blue:hover{background:linear-gradient(90deg, #53C0D5 0%, #726CC2 100%); color:#fff; border:0;}

/* ========================
          HEADER
======================== */
header{float:left; width:100%; box-shadow:none !important;}
.MuiToolbar-root{justify-content:space-between; background:linear-gradient(90deg, #53C0D5 0%, #726CC2 100%);}
.head-right{float:right; display:flex; margin-top:9px;}
.head-right a.call{float:left; color:#fff; display:flex; align-items:center;font-family:'Prata';}
.head-right a.call img{float:left; margin-right:7px;} 
.head-right button{margin-left:20px; height: 36px; font-size: 14px; border-radius:0px; -moz-border-radius:0px; -webkikt-border-radius:0px;}
.head-right button .MuiButton-label{font-family:'Prata' !important; text-transform:capitalize;}


/* ========================
        HOME BANNER
======================== */
.home-banner{padding:0;}
.home-banner .MuiGrid-container{align-items:center;}
.home-banner .img-box{float:left; width:100%;}
.home-banner .img-box img{float:left; width:100%;}
.home-banner .data{float:left; width:100%; padding:30px 0;}
.home-banner .data h1{ font-family:'Prata' !important; text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);}
.home-banner .data h5{ font-family:'Prata' !important; font-size: 15px; color: #313131;}
.home-banner .data .icon{float:left; margin-right:15px;}
.home-banner .data button{ margin-top:20px; }
.home-banner .data button span{font-family:'Prata' !important;}
.home-banner-slidebox{order: 1;}

/* ========================
        HOME ABOUT
======================== */  
.home-about h4{font-family:'Prata' !important; margin-bottom:0; margin-bottom: 15px;} 
.home-about h5{font-weight:500; display: flex; align-items: center; font-size: 15px; font-family:'Prata' !important;}
.home-about h5 i{float:left; width: 24px; margin-right:10px;}
.home-about .data {float:left; width: 100%;}
.home-about .data p{float:left; width: 100%;font-family:'Prata'; text-align: justify; color: #A0A0A0;}
.home-about table{width: 100%;}
.home-about table td,.home-about table th{font-family:'Prata' !important;}
.home-about table th{text-align:left; padding:5px 0; font-weight:normal; color: #757474;}
.home-about table td{text-align:right; padding:5px 0; color:#14B9DB;}
.home-about .map{float:left; width:100%; border-radius:7px;  -moz-border-radius: 7px;  -webkit-border-radius: 7px; overflow: hidden;}
.home-about .map iframe{float:left; width:100%; height:260px;}

/* ========================
        HOME LOCATION
======================== */ 
.home-location {background:#ECEBF4;}
.home-location h2{font-weight:600; margin-bottom:0;} 
.home-location .inside{float:left; width:100%;}
.home-location .imgbox{float:left; width:100%;}
.home-location .imgbox img{float:left; width:100%; max-width:none;}
.home-location .data{float:left; width:100%;}
.home-location .data p{color:#828282;}
.home-location .data h5{font-weight:600;}
.home-location .MuiCardContent-root{float:left; width:100%;}
.home-location .rating{margin-left:0;}
.home-location .rating-wrapp span{margin-right:10px; color:#828282;}
.home-location .rating-wrapp span:nth-last-child(2){font-weight:600; color:#000;}

/* ========================
        POPUP
======================== */ 
.popup-container{min-height: 460px;}
.main-popup .MuiDialog-paperWidthLg{width: 100%;}
.main-popup .MuiTypography-h6{display:flex; justify-content:center; flex-direction:column; color:#fff; align-items:center; position:relative; font-weight:600; font-size:28px;} 
.main-popup .MuiTypography-h6 .ttl-txt{display: inline-block; margin-top: 5px;}
.main-popup .MuiTypography-h6 button{position:absolute; right:0px;} 
.main-popup .MuiDialogTitle-root{padding:5px 15px; background:linear-gradient(90deg, #53C0D5 0%, #726CC2 100%); }
.main-popup .MuiDialogContent-root{min-height:63vh; overflow-x:hidden; padding: 10px 20px;}
.btn-book-now-wrapp{padding-right: 30px;}
.main-popup .btn-booknow{width:100%;}
.main-popup .btn-booknow .MuiButton-label{white-space: nowrap;}
.main-popup .btn-back{position:absolute; left:0px; font-size:15px; font-weight:400;}
.main-popup .btn-reschedule{background: #14B9DB;}

/*======= DAY LIST =========*/
.react-multi-carousel-list { float:right; width:calc(100% - 60px); border:1px solid rgba(189, 189, 189, 0.4); padding:10px; border-radius:10px; -moz-border-radius:10px; -webkit-border-radius:10px; overflow:hidden; position:relative;}
.react-multi-carousel-track {float:left;  display:flex; }
.react-multi-carousel-track  li{float:left; /* width:auto !important; */}
.react-multi-carousel-track  li span{float:left;width:100%;}
.react-multi-carousel-track  li a{float:left; padding:0 15px; font-weight:500; position:relative;  color:rgba(8, 43, 68, 0.7); text-align:center;}
.react-multiple-carousel__arrow {float:left;width:20px; height:20px;   border-radius:100%; -moz-border-radius:100%; -webkit-border-radius:100%; position:absolute; top:0;}
.react-multiple-carousel__arrow--right{background:url(/images/slider-R.png) no-repeat 50%#fff; right:0;}
.react-multiple-carousel__arrow--left{background:url(/images/slider-L.png) no-repeat 50% #fff; left:0;}

/*======= CATEGORY LIST =========*/
.category-slider{float:left; width:100%;}
.category-slider .slick-list{float:left; width:100%; border:1px solid rgba(189, 189, 189, 0.4); padding:14px 30px 13px; border-radius:10px; -moz-border-radius:10px;  -webkit-border-radius:10px; padding: 14px 40px;}
.category-slider .slick-slide{float:left;}
.category-slider .slick-slide > div{float:left;}
.category-slider .slick-slide a{float:left; padding:0 20px 0 10px; font-weight:500; position:relative; text-align:center; color:rgba(8, 43, 68, 0.7)}
.category-slider .slick-slide a.active{ border-color:#14B9DB; color:#14B9DB;}
.category-slider .slick-slide a:after{content:"/"; float:left; position:absolute; right:0; top:0;}
.category-slider .slick-slide:last-child a:after{display:none;}
.category-slider button.slick-arrow{float:left;width:40px; height:calc(100% - 2px); border-radius:7px; -moz-border-radius:7px; -webkit-border-radius:7px; position:absolute; top:1px;  box-shadow:none; transform:none;}
.category-slider button.slick-arrow.slick-next{background:url(/images/slider-R.png) no-repeat 50%#fff; right:2px;}
.category-slider button.slick-arrow.slick-prev{background:url(/images/slider-L.png) no-repeat 50% #fff; left:2px;}

/*======= TIME LIST =========*/
.time-slider{float:right; width:calc(100% - 50px); padding:0 30px;}
.time-slider .slick-list{float:left; width:100%;}
.time-slider .slick-slide{float:left; padding:0 3px;}
.time-slider .slick-slide a{text-align:center; font-weight:400; font-size:13px; line-height: 1.2; padding:2px 7px; border:1px solid #E6EAEC; border-radius:10px; -moz-border-radius:10px;  -webkit-border-radius:10px; color:#000;}
.time-slider .slick-slide a.active{border-color:#14B9DB; color: #14B9DB;}
.time-slider .slick-slide a.active span{color:#89DCED;}
.time-slider .slick-slide a span{float:left; width:100%; color:#8395A1;} 
.time-slider .slick-slide a:after{display:none;}
.time-slider button.slick-arrow{float:left;width:20px; height:calc(100% - 2px); border-radius:0; -moz-border-radius:0; -webkit-border-radius:0; position:absolute; top:1px;  box-shadow:none; transform:none;}
.time-slider button.slick-arrow.slick-next{background:url(/images/slider-R.png) no-repeat 50%#fff; right:1px;}
.time-slider button.slick-arrow.slick-prev{background:url(/images/slider-L.png) no-repeat 50% #fff; left:1px;}

/*======= SEARCH BOX =========*/
.search-box{float:left; width:100%; position:relative; margin-bottom:20px;}
.search-box .MuiTextField-root{float:left; width:100%;}
.search-box .MuiTextField-root .MuiTextField-root,
.search-box .MuiTextField-root .MuiOutlinedInput-input,
.search-box .MuiTextField-root .MuiOutlinedInput-input,
.search-box .MuiTextField-root .MuiFormLabel-root{padding-left:50px !important; background:#fcfcfc;}
.search-box .MuiTextField-root .MuiFormLabel-root.Mui-focused,
.search-box .MuiTextField-root .MuiFormLabel-root.MuiFormLabel-filled{padding-left:0px !important;}
.search-box svg{position:absolute; left:20px; top:50%; transform:translateY(-50%); z-index:2;fill:#80919E;}
.MuiInputLabel-outlined {transform: translate(13px, 100%) scale(1);}

/*======= LIST OF RADIO =========*/ 
.list-of-radio-wrapp{float:left; width:100%;}
.list-of-radio-wrapp h3,.list-of-radio-wrapp h5{margin-bottom:10px; color:#000; font-size:16px; font-weight:500;}
.list-of-radio,.MuiFormGroup-root{float:left; width:100%;}
.list-of-radio{height:400px; overflow:auto; padding-right:15px;}
.list-of-radio li {float:left; width:100%; border-bottom:1px solid #F4F4F4;}
.list-of-radio li .amt{float:right; color:#000; padding-top:13px;}
.list-of-radio li .time{float:right; margin-right:10px; padding-top:13px; color: #8395A1;}
.list-of-radio li .job{float:right; color:#8395A1;}
.list-of-radio li p{float:left; width:100%; padding-left:32px; color: #8395A1; font-size: 12px; font-weight: 300; line-height: 1.5;}
.list-of-radio li .MuiFormControlLabel-root{float:left; padding-left: 9px;}
.list-of-radio li .MuiFormControlLabel-label{float:left; font-weight:400; color:#000; font-family:'Poppins', sans-serif; font-size: 15px;}

/*======= SIDEBAR POPUP =========*/  
.popup-sidebar{float:left; width:100%; box-shadow:5px 5px 10px rgba(0, 0, 0, 0.05); border:1px solid rgba(189, 189, 189, 0.4); border-radius:10px; -moz-border-radius:10px; -webkit-border-radius:10px;}
.popup-sidebar .popup-sidebar-ttl-info{float:left; width:100%; padding:10px 15px; text-align:center; color:#000; border-bottom:1px solid #F4F4F4;}
.popup-sidebar .popup-sidebar-ttl-info .rating-wrapp{display:flex; justify-content:center; margin-top:10px;}
.popup-sidebar .popup-sidebar-ttl-info h3{font-size: 20px; margin-bottom: 15px;}
.popup-sidebar .popup-sidebar-ttl-info span{font-size:15px; }
.popup-sidebar .popup-sidebar-tbl{float:left; width:100%; max-height:400px; overflow: auto;}
.popup-sidebar .popup-sidebar-tbl table{float:left; width:100%;  border-collapse:collapse;}
.popup-sidebar .popup-sidebar-tbl table td{ padding:10px 15px; vertical-align:top;}
.popup-sidebar .popup-sidebar-tbl table td label{display:inline-block; width:100%; color:#000; white-space:nowrap; text-overflow:ellips;}
.popup-sidebar .popup-sidebar-tbl table td span{display:inline-block; width:100%; color:#92A2AC; font-size:14px;}
.popup-sidebar .popup-sidebar-tbl table td strong{display:inline-block; width:100%; font-weight:700;}
.popup-sidebar .popup-sidebar-tbl table td:last-child{text-align: right;}
.popup-sidebar .popup-sidebar-tbl table .ttl-amt{ border-top:1px solid #f4f4f4;}

.popup-sidebar .popup-sidebar-time{float:left; width:100%; padding:15px; color:#000; border-bottom:1px solid #F4F4F4;}
.popup-sidebar .popup-sidebar-time strong{font-weight:400; color:#6B808F;}
.popup-sidebar .popup-sidebar-time p{margin-bottom:0;}

/*======= TECH LIST =========*/  
.list-of-technicians{float:left; width:100%;}
.list-of-technicians li.item{float:left; width:100%; padding:10px 0;}
.list-of-technicians li.item .img-box{float:left; width:40px; height:40px; border-radius:100%; -moz-border-radius:100%; -webkikt-border-radius:100%; overflow:hidden; margin-right:15px;}
.list-of-technicians li.item .img-box + .MuiFormControlLabel-root{ width:25%; padding-left: 0;}
.list-of-technicians li.item .job{float:right; margin-top: 10px;}
.list-of-technicians li.item .rating-wrapp{width:auto; margin-top: 6px;}

/*======= TIME LIST =========*/  
.list-of-time{display:flex; flex-wrap:wrap;}
.list-of-time li{ width:33.333%;}
.list-of-time li .MuiFormControlLabel-label{font-weight:400;}

/*======= TIME LIST =========*/   
.form-box{float:left; width:100%; max-width:560px; position:relative; left:50%; transform:translate(-50%); padding-left: 65px;}
.form-box-inside{max-width: 374px; width: 100%; float: left;}
.form-box h5,.form-box h3{margin-bottom:22px;}
.form-box .fm-ttl{float:left; width:100%; margin-bottom:5px; color:#082B44; font-size:14px;} 
.form-box .fm-ttl .err{float: none; width: auto; display: inline-block; margin-left: 10px;}
.form-box .MuiFormControl-root{float:left; width:100%; margin-bottom:22px;} 
.form-box button,.form-box button:hover{font-size:22px; width:100%; text-align:center; margin-top:10px;  border-radius:10px; -moz-border-radius:10px; -webkikt-border-radius:10px; background: #14B9DB;}
.enter-phone-no .MuiFormControl-root{margin-bottom:25px;}
.enter-phone-no h5{font-weight: 400; font-size: 16px;}
.enter-phone-no button.MuiButton-root{margin-top:10px;}
.insert-activate-code .MuiFormControl-root{margin-bottom:25px;}
.insert-activate-code button.MuiButton-root{margin-top:10px;}

/*======= APPONTMENT DETAILS =========*/   
.appointment-details{float:left; width:100%; max-width:560px; position:relative; left:50%; transform:translate(-50%); padding-left: 65px;}
.appointment-details h3{margin-bottom:15px; font-size: 20px; font-weight: 400;}
.appointment-details h3 span.name{color:#14B9DB;}
.appointment-details h5{ font-size: 18px; font-weight: 400;}
.appointment-details .time-list{float:left; width:100%; padding-right: 15px; margin-top:15px; max-height: 475px; overflow: auto;}
.appointment-details .time-list li{float:left; width:100%;  font-weight: 400; padding:15px 0; font-size:16px; border-top:1px solid #F4F4F4; border-bottom:1px solid #F4F4F4; margin-bottom:-1px;}
.appointment-details .time-list li a{float:left;}
.appointment-details .time-list li a.active{color:#14B9DB;}
.appointment-details .btn-blue{margin-top:20px; height: 45px; clear:both; float:left; font-size:17px; margin-top: 20px; }
.upcoming-popup-container .btn-box button{ height: 45px; font-size:17px;}

.btn-box{ float:left; width: 100%; display: flex; justify-content: space-between; margin-top: 15px;}
.btn-box button{font-size:20px;}
button.btn-cancel,button.btn-cancel:hover{background: #F46451;}

.btn-main{ padding: 5px 40px; border-radius:30px; -webkit-border-radius:30px; -moz-border-radius:30px; -ms-border-radius:30px; -o-border-radius:30px; cursor: pointer; border: 1px solid rgba(20, 185, 219, 0.3);}
.btn-main[disabled]{border: 1px solid rgba(20, 185, 219, 0.3); background: rgba(20, 185, 219, 0.3); pointer-events: none;}  
/*======= Footer =========*/ 
footer{float:left; width:100%; padding:5px 0; background:#87D9EA; color:#fff;}
footer .container{    display: flex; justify-content: space-between; align-items: center;}
.copyright{float:left; font-family:'Prata'; font-size: 12px; margin: 0;}
.social-links{float:right; display:flex;}

.msg-cancel{float: left; width: 100%; margin-top:20px;}
.msg-cancel h6{margin-bottom: 0; text-align: center;}
.msg-cancel .btn-box{justify-content: center;}
.msg-cancel .btn-box button{margin: 0 10px;}
.msg-cancel .btn-box button.btn-no{background: #14B9DB;}


.mob-data{float: left; width: 100%; padding-right: 10px; display: none; }
.mob-data span{float: left; font-size: 12px;}
.mob-data span.pdngL5{padding-left: 5px;}
.mob-data span.amt{font-weight: 500; float: right;}
.mob-data .mob-data-btm{float: left; width: 100%; font-size: 12px; color: #8395A1; margin-bottom: 0;}
.mob-data .mob-data-btm p{float: none; width: auto; display: inline-block; font-size: 12px; color: #8395A1; margin-bottom: 0;}
.mob-data .mob-data-btm span{float: left; width: 100%;}
.mob-data .mob-data-btm strong{display: inline-block; font-weight: normal; color: #082B44;}
/* .mob-data .mob-data-btm strong:last-child{float: right;} */
.chklist{display: inline-block; }
.chklist-item{display: inline-block; margin-right: 15px; }
.chklist-item:last-child{ margin-right:0px; }
.chklist-item > span{padding: 0; margin-right:2px;}

.covid-consent-form__list{float:left; width:100%; margin-top: 25px; } 
.covid-consent-form__list li{float:left; width:100%; margin-bottom:20px; list-style:none; color:#000;}
.covid-consent-form__list li:last-child{ margin-bottom:0px; }

.covid-consent-form{float:left; width:100%;}
.covid-consent-form h3{float:left; width:100%; font-weight: 700; font-size: 20px; }
.covid-consent-form h4{float:left; width:100%; font-weight: 700; font-size:16px; }
.covid-consent-form h5{float:left; width:100%; font-size:18px; color:#082B44; margin-top:30px; }
.covid-consent-form .subTtl{float:left; width:100%; font-size:15px; text-align: center; margin-top: 5px; }
.covid-consent-form .chklist.last{padding-left: 10px;}
.covid-consent-form .chklist.first{padding-right: 10px;}
.covid-consent-form .btn-box{display: flex; justify-content: center;} 

.text-center{text-align: center;}
.mrgnBtm0{margin-bottom: 0;}


.covid-consent-form__btm{ max-width: 500px; position: relative; left: 50%; transform: translateX(-50%); -webkit-transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%); -o-transform: translateX(-50%); text-align: center; margin-top: 50px; }
.covid-consent-form__btm span{width: 100%; display: block;}
.covid-consent-form__btm .MuiInputBase-input{text-align: center;}

.covid-consent-form__name{float: left; width:100%;}
.covid-consent-form__name li{float: left; width:100%; margin-bottom: 15px;}
.covid-consent-form__name li label{float: left; color: #525151;}
.covid-consent-form__name li span{float: left;  color: #000; border-bottom: 1px solid rgba(95, 135, 161, 0.33); padding: 0 15px;}

.covid-consent-form-popup{float: left; width: 100%; height: 100%; background: rgba(95, 135, 161, 0.33); position: absolute; left: 0; top: 0; display: flex; align-items: center;justify-content: center; padding: 15px; z-index: 10;}
.covid-consent-form-popup-aside{ float: left; width: 100%; max-width: 500px; background: #fff; border-radius:14px; -webkit-border-radius:14px; -moz-border-radius:14px; -ms-border-radius:14px; -o-border-radius:14px; text-align: center; padding:15px 0; }
.covid-consent-form-popup-aside h5{margin-top: 0; padding:0 25px; font-size: 18px; color:#000; font-weight: 700;}
.covid-consent-form-popup-aside p{margin-bottom: 0; border-bottom: 1px solid rgba(0, 0, 0, 0.15); padding: 15px; font-size: 16px; color: #616262;}
.covid-consent-form-popup-aside a{display: inline-block; font-size: 17px; color: #F46451; margin-top: 10px;}

/* shaffi */
.note-block p{font-weight: bold;padding-top: 20px;}
.note-block span{font-weight: normal; cursor: pointer;}
.note-block textarea, .booking-note{height: 60px;border: 1px solid lightgrey;width: 100%;border-radius: 5px;padding: 5px 5px 5px 10px; margin-bottom: 20px;}
.noteIcons{float: none; vertical-align: middle; cursor: pointer;}
.covid-consent-sign-popup-aside{ float: left; width: 100%; height: 100%; max-width: 800px; max-height: 500px; background: #fff; border-radius:14px; -webkit-border-radius:14px; -moz-border-radius:14px; -ms-border-radius:14px; -o-border-radius:14px; text-align: center; padding:15px 0; }
.sigImage {background-size: 200px 50px;width: 200px;height: 50px;background-color: white;}
.canvasBtns{float: right;margin-right: 40px;}
.mr_10{margin-right: 10px !important;}
.cursor_pointer{cursor: pointer;}
.bg_color_red{background-color: red !important;}
/* ================================================================================================
                                R E S P O N S I V E
================================================================================================ */ 
@media screen and (max-width:959px){
        h1{font-size:26px;}   
        h2{font-size:22px;}   
        h3{font-size:20px;}  
        h4{font-size:18px;}  
        h5{font-size:15px;}  
        h6{font-size:14px;}  
       .logo img{width: 120px;}
       .head-right button{height: 30px; font-size: 12px;}
       .head-right{margin-top: 4px;}
       button.MuiButton-root{padding: 2px 20px;}
       .MuiToolbar-regular {min-height: 50px;}
       .home-banner .data button{padding: 10px 20px 7px;}
       button.slick-arrow{width: 36px; height: 36px;}
       button.slick-arrow.slick-prev{left: 15px;}
       button.slick-arrow.slick-next{right: 15px;}
       .main-popup .MuiTypography-h6{font-size:22px;}
       .bult-steps{margin-top: 0;}
       .bult-steps li a{width: 7px; height: 7px;}
       .MuiDialogTitle-root{padding:5px 15px;}
       .MuiIconButton-root{padding: 5px;}
       .list-of-radio{height: auto; padding-right: 0;}
       .home-about .data{margin-top: 20px;}
       header .container{display: flex; justify-content: space-between; align-items: flex-end;}
       .main-popup .MuiTypography-h6 .ttl-txt{font-size: 18px; font-weight: 500;}
       .popup-sidebar{margin-top: 20px;}
       .btn-book-now-wrapp{padding-right:15px;}
       .form-box,.appointment-details{padding-left: 0;}
       .list-of-radio-wrapp{margin-top: 0 !important;}
       .form-note{margin-top: 20px !important;}
       .enter-phone-no .MuiFormControl-root{margin-bottom: 15px;}
       .MuiOutlinedInput-input{height: 10px 20px !important;}
}
@media screen and (max-width:767px){
        .hide-in-mob{display: none !important;}
        .mob-data{display: block; padding-left:15px;}
}
@media screen and (max-width:640px){ 
        .head-right a.call{display: none;}
        .head-right button .MuiButton-label{font-size: 12px;}
        .MuiButton-root{font-size: 12px;}
        .copyright{font-size: 12px; width: 100%; text-align: center; margin-bottom: 0px; }
        .social-links{width: 100%; display: flex; justify-content: center; display: none;}
        .main-popup .MuiTypography-h6{font-size:18px;}
        .category-slider .slick-list .slick-track{display: flex; flex-direction: row;}
        .category-slider .slick-slide a{font-family: arial; padding: 0 15px 0 10px; border:1px solid rgba(189, 189, 189, 0.4); padding:3px 5px; border-radius: 5px; -moz-border-radius: 5px; -webkit-border-radius: 5px; white-space: nowrap;}
        .category-slider .slick-slide a:after{display: none;}
        .category-slider .slick-list{padding:0px; border: none; border-radius: 0;}
        .category-slider .slick-slide{padding: 0 3px;}
        .category-slider .slick-slider,.category-slider .slick-track{float: left; width: 100%;}
        /* .category-slider .slick-slide > div{padding: 0 3px;} */
        .search-box .MuiTextField-root .MuiFormLabel-root{font-size: 12px;}
        .popup-sidebar .popup-sidebar-ttl-info h3{font-size: 16px; margin-bottom: 10px;}
        .popup-sidebar .popup-sidebar-ttl-info span{font-size: 13px;}
        .list-of-radio li .MuiFormControlLabel-root{width: 70%;}        
        .time-popup .list-of-radio li .MuiFormControlLabel-root{width:100%; padding: 0;}
        .list-of-technicians li.item .img-box{display: none;}
        .btn-back span{display: none;}
        .list-of-technicians li.item .job{display: none;}
}
@media screen and (max-width:599px){ 
        h1, h2, h3, h4, h5, h6{margin-bottom: 10px;}
        .home-about .map{margin-bottom:20px;}
        .home-about .data{margin-top:20px;}
        .home-banner-slidebox{order:0;}
        .home-banner .data{padding: 0;}
        .home-banner .data h5{margin-bottom: 5px;}
        body{padding-bottom:70px;}
        button.MuiButton-root{height: 40px; font-size: 16px;}
        .home-banner .home-banner-slidebox{padding:12px 0 !important;}
        .MuiGrid-spacing-xs-3 {width: calc(100% + 32px); margin: 0 -16px;}
        .container{padding: 0 12px;}
        .btn-mob-stick{display: flex;}
        .home-about h5 i{width: 18px;}
        .home-about table th{padding: 3px 0;}
        .home-about .map iframe{height: 175px;}
        body{font-size: 12px;}
        .home-about h4,.home-about h5,.home-banner .data h5,.rating-wrapp span{font-size: 12px;}
        .home-banner .data h1{font-size: 20px;}
        .category-slider .slick-slide a{font-weight: 400;} 
        .MuiInputBase-input{font-size: 14px;}
        .list-of-radio li .MuiFormControlLabel-label{font-size: 14px;}
        .category-slider .slick-slide a{font-size: 15px;}
        .list-of-radio li .amt{padding-top: 8px; font-size: 14px;}
        .list-of-radio li .time{padding: 0 0 5px 45px; width: 100%; font-size: 14px;}
        .main-popup .MuiTypography-h6 .ttl-txt{margin-top: 0;}
        .main-popup .MuiTypography-h6 button{margin-right: -15px;}
        .main-popup .btn-booknow{width: auto; float: right;}
        .rating-wrapp .star-ratings svg{width: 15px !important; height:15px !important;}
        .list-of-technicians li.item .rating-wrapp{margin-top: 11px; float: right;}
        .list-of-technicians li.item .job{margin-top: 14px;}
        .appointment-details .btn-blue{font-size: 15px; width: 100%;}
        .time-slider{padding: 0;}
        .enter-phone-no h5{font-size: 14px;}
        .enter-phone-no .MuiInputBase-input{font-size: 14px;}
        .popup-sidebar-wrapp{margin-top: 0 !important;}
        .covid-consent-form .chklist.first { padding-right:4px; }
        .covid-consent-form .chklist.last { padding-left:4px; }
        
}
@media screen and (max-width:420px){
        .head-right button .MuiButton-label{font-size: 10px;}
}
@media screen and (max-width:400px){
        .main-popup .MuiDialog-paperWidthLg{margin: 0;}
        .MuiDialog-paperScrollPaper{max-height: 100% !important; height: 100%;}
        .mob-data span{font-size: 11.5px;}        
        .mob-data{ padding:0px;}
        .btn-book-now-wrapp { padding-right: 0px;}
}
@media screen and (max-width:359px){        
        .category-slider .slick-slide a{font-size: 13.5px;}
        .btn-book-now-wrapp{padding-right: 0;}
        .mob-data{padding-left: 0;}
        button.MuiButton-root{padding: 2px 15px; font-size: 14px;}
        .list-of-radio li .MuiFormControlLabel-label { font-size: 13px; white-space: nowrap; }
        .list-of-time li .MuiButtonBase-root{ padding-right: 5px; }
}
input#agree {
    position: relative;
    top: 2px;
}